import * as React from 'react';
import { st, classes } from './OfferingImage.st.css';
import {
  getFillImageUrl,
  getFitImageUrl,
} from '../../adapters/media-gallery/media-gallery-adapter';
import { ImageResizeOptions } from '../../../Shared/appKeys/SettingsKeys';
import { ImageDto } from '@wix/bookings-uou-domain';
import {
  HoverEffectOptions,
  LoadingBehaviorOptions,
  ResizeOptions,
  ThumbnailImage,
} from 'wix-ui-tpa/ThumbnailImage';
import { DEFAULT_IMAGE_CONTAINER } from '../OfferingView/OfferingView.const';

interface OfferingImageProps {
  mediaItem: ImageDto;
  width: number;
  height: number;
  imageResize: ImageResizeOptions;
  isRenderImgElement: boolean;
  focalPoint: { x: number; y: number };
  title: string;
  aspectRatio: number;
}

export class OfferingImage extends React.PureComponent<OfferingImageProps> {
  private getImageUrl() {
    const { mediaItem, width, height, imageResize, focalPoint } = this.props;

    if (imageResize === ImageResizeOptions.CROP) {
      return getFillImageUrl(mediaItem, { width, height }, focalPoint);
    }
    return getFitImageUrl(mediaItem, { width, height });
  }

  render() {
    const {
      mediaItem,
      imageResize,
      focalPoint,
      height,
      isRenderImgElement,
      aspectRatio,
      title,
    } = this.props;

    if (mediaItem) {
      return isRenderImgElement ? (
        <ThumbnailImage
          alt={title}
          fluid={true}
          focalPoint={focalPoint}
          loadingBehavior={LoadingBehaviorOptions.blur}
          aspectRatio={aspectRatio}
          hoverEffect={HoverEffectOptions.darken}
          resize={
            imageResize === ImageResizeOptions.CROP
              ? ResizeOptions.cover
              : ResizeOptions.contain
          }
          data-hook="image-container"
          className={st(classes.rootImg)}
          src={
            height !== DEFAULT_IMAGE_CONTAINER.height
              ? this.getImageUrl()
              : mediaItem.relativeUri
          }
        />
      ) : (
        <div
          data-hook="image-container"
          style={{ backgroundImage: `url(${this.getImageUrl()})` }}
          className={st(classes.root, { imageResize })}
        />
      );
    }
    return null;
  }
}
